// import HelloWorld from "./hello-world.vue";
import { createApp, ref, onMounted } from "vue";
// createApp(HelloWorld).mount("#hello-world");

import { Dialog, DialogPanel, TransitionRoot } from "@headlessui/vue";
import XMarkIcon from "@heroicons/vue/24/outline/XMarkIcon";
import Bars3Icon from "@heroicons/vue/24/outline/Bars3Icon";
import LocalePopover from "./components/LocalePopover.vue";
import BasePopover from "./components/BasePopover.vue";
import ImageCarousel from "./components/ImageCarousel.vue";
import BaseLightbox from "./components/BaseLightbox.vue";
import ContactForm from "./components/ContactForm.vue";
import ContactMap from "./components/ContactMap.vue";
import CookieNotice from "./components/CookieNotice.vue";

createApp({
  components: {
    "transition-root": TransitionRoot,
    "headless-dialog": Dialog,
    "dialog-panel": DialogPanel,
    "bars-icon": Bars3Icon,
    "close-icon": XMarkIcon,
    LocalePopover,
    BasePopover,
    ImageCarousel,
    BaseLightbox,
    ContactForm,
    ContactMap,
    CookieNotice,
  },
  setup(props, ctx) {
    const mobileMenuOpen = ref(false);
    const loaded = ref(false);

    onMounted(() => {
      loaded.value = true;
    });
      
    return {
      mobileMenuOpen,
      loaded,
    };
  },
}).mount("#app");

import.meta.webpackHot?.accept(console.error);
