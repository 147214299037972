
      var API = require("!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
      var domAPI = require("!../../node_modules/style-loader/dist/runtime/styleDomAPI.js");
      var insertFn = require("!../../node_modules/style-loader/dist/runtime/insertBySelector.js");
      var setAttributes = require("!../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js");
      var insertStyleElement = require("!../../node_modules/style-loader/dist/runtime/insertStyleElement.js");
      var styleTagTransformFn = require("!../../node_modules/style-loader/dist/runtime/styleTagTransform.js");
      var content = require("!!../../node_modules/@roots/bud-support/lib/css-loader/index.cjs??css!../../node_modules/postcss-loader/dist/cjs.js??postcss!./app.css");
      
      content = content.__esModule ? content.default : content;

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);


if (module.hot) {
  if (!content.locals || module.hot.invalidate) {
    var isEqualLocals = function isEqualLocals(a, b, isNamedExport) {
  if (!a && b || a && !b) {
    return false;
  }
  var p;
  for (p in a) {
    if (isNamedExport && p === "default") {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (a[p] !== b[p]) {
      return false;
    }
  }
  for (p in b) {
    if (isNamedExport && p === "default") {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!a[p]) {
      return false;
    }
  }
  return true;
};
    var isNamedExport = false;
    var oldLocals = isNamedExport ? namedExport : content.locals;

    module.hot.accept(
      "!!../../node_modules/@roots/bud-support/lib/css-loader/index.cjs??css!../../node_modules/postcss-loader/dist/cjs.js??postcss!./app.css",
      function () {
        content = require("!!../../node_modules/@roots/bud-support/lib/css-loader/index.cjs??css!../../node_modules/postcss-loader/dist/cjs.js??postcss!./app.css");

              content = content.__esModule ? content.default : content;

              if (typeof content === 'string') {
                      content = [[module.id, content, '']];
                    }

              if (!isEqualLocals(oldLocals, content.locals)) {
                module.hot.invalidate();

                return;
              }

              oldLocals = content.locals;

              update(content);
      }
    )
  }

  module.hot.dispose(function() {
    update();
  });
}


module.exports = content && content.locals || {};
